import axios from "axios";
import Cookies from "universal-cookie";

import {
    AddShipmentTrackingDataType,
    BrokerApproveItem,
    ContainerDetailsType,
    ContainerNumberType,
    ContainerType,
    CreatePortalContainerType,
    DetailFileObjectType,
    DetailListStateType,
    InvoiceDetailType,
    InvoiceFiltersData,
    InvoiceType,
    OrderFilterDataType,
    PortalActionTypes,
    OrdersListStateType,
    SetAirShipmentsFiltersDataType,
    SetAirShippingListType,
    SetAirShippingLoadingType,
    SetBrokerApproveListAllType,
    SetBrokerApproveListType,
    SetContainerDetailsType,
    SetContainerNumbersType,
    SetContainersListAllType,
    SetContainersListType,
    SetCreateContainerErrorType,
    SetDetailListType,
    SetDetailLoadingType,
    SetFilterOrderDataType,
    SetInvoiceDetailsType,
    SetInvoiceFiltersDataType,
    SetInvoiceListType,
    SetInvoiceLoadingType,
    SetIsDownloadingDetailsType,
    SetIsDownloadingInvoiceType,
    SetIsDownloadingType,
    SetIsLoadingProductType,
    SetIsLoadingType,
    SetListContainersForDownloadType,
    SetLoadingBrokerApproveType,
    SetLoadingContainerFileType,
    SetLoadingTrackingType,
    SetOrdersListAllType,
    SetOrdersListType,
    SetShipmentsFiltersDataType,
    SetTaxRuleListAllType,
    SetTaxRuleListType,
    SetTaxRuleLoadingType,
    ShipmentsFilterDataType,
    TaxRuleType,
    TaxRuleUpdateType,
    ThunkType,
    TSetLoadingContainer,
    TPortalLoadingStates,
    TSetLoadingStates,
} from "./types/PortalTypes/portal.types";
import { displayError, displaySuccess } from "../hooks/useErrorHandler";
import { LAZY_COUNT } from "../components/DashboardWarehouse/common/Constants";
import { PeriodDetailFilterType } from "../components/DashboardPortal/AirShipping/AirShippingTab";
import { EditContainerSubmitData } from "../components/DashboardPortal/EditContainerModal/EditContainerModal";
import { packingPortalAPI, invoicePortalAPI, detailPortalAPI, taxRulePortalAPI, airShippingPortalAPI, brokerApproveAPI } from "../api/portalAPI";

export const SET_IS_LOADING_PRODUCT = "SET_IS_LOADING_PRODUCT";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ORDERS_LIST = "SET_ORDERS_LIST";
export const SET_ORDERS_LIST_ALL = "SET_ORDERS_LIST_ALL";
export const SET_CONTAINERS_LIST = "SET_CONTAINERS_LIST";
export const SET_CONTAINERS_LIST_ALL = "SET_CONTAINERS_LIST_ALL";
export const SET_INVOICE_LIST = "SET_INVOICE_LIST";
export const SET_INVOICE_LOADING = "SET_INVOICE_LOADING";
export const SET_DETAIL_LIST = "SET_DETAIL_LIST";
export const SET_DETAIL_LOADING = "SET_DETAIL_LOADING";
export const SET_TAX_RULE_LOADING = "SET_TAX_RULE_LOADING";
export const SET_TAX_RULE_LIST = "SET_TAX_RULE_LIST";
export const SET_TAX_RULE_LIST_ALL = "SET_TAX_RULE_LIST_ALL";
export const SET_CREATE_CONTAINER_ERROR = "SET_CREATE_CONTAINER_ERROR";
export const SET_LIST_CONTAINERS_FOR_DOWNLOAD = "SET_LIST_CONTAINERS_FOR_DOWNLOAD";
export const SET_IS_DOWNLOADING = "SET_IS_DOWNLOADING";
export const SET_IS_DOWNLOADING_INVOICE = "SET_IS_DOWNLOADING_INVOICE";
export const SET_IS_DOWNLOADING_DETAIL = "SET_IS_DOWNLOADING_DETAIL";
export const SET_AIR_SHIPPING_LIST = "SET_AIR_SHIPPING_LIST";
export const SET_AIR_LOADING = "SET_AIR_LOADING";
export const SET_CONTAINER_NUMBERS = "SET_CONTAINER_NUMBERS";
export const SET_FILTER_ORDERS_DATA = "SET_FILTER_ORDERS_DATA";
export const SET_CONTAINER_DETAILS = "SET_CONTAINER_DETAILS";
export const SET_INVOICE_DETAILS = "SET_INVOICE_DETAILS";
export const SET_INVOICE_FILTERS_DATA = "SET_INVOICE_FILTERS_DATA";
export const SET_SHIPMENTS_FILTERS_DATA = "SET_SHIPMENTS_FILTERS_DATA";
export const SET_AIR_SHIPMENTS_FILTERS_DATA = "SET_AIR_SHIPMENTS_FILTERS_DATA";
export const SET_BROKER_APPROVE_LIST = "SET_BROKER_APPROVE_LIST";
export const SET_BROKER_APPROVE_LIST_ALL = "SET_BROKER_APPROVE_LIST_ALL";
export const SET_LOADING_BROKER_APPROVE = "SET_LOADING_BROKER_APPROVE";
export const SET_LOADING_TRACKING = "SET_LOADING_TRACKING";
export const SET_LOADING_CONTAINER_FILE = "SET_LOADING_CONTAINER_FILE";
export const SET_LOADING_CONTAINER = "SET_LOADING_CONTAINER";
export const SET_LOADING_STATES = "SET_LOADING_STATES";

type InitialStateType = {
    isLoading: boolean;
    isLoadingProduct: boolean;
    isLoadingInvoice: boolean;
    isLoadingDetail: boolean;
    isLoadingTaxRule: boolean;
    isLoadingContainerFile: boolean;
    isDownloading: boolean;
    isDownloadingInvoice: boolean;
    isDownloadingDetail: boolean;
    ordersList: null | OrdersListStateType;
    ordersListAll: null | OrdersListStateType;
    containersList: null | ContainerType[];
    containersListAll: null | ContainerType[];
    invoiceList: null | InvoiceType[];
    detailList: null | DetailListStateType;
    taxRuleList: null | TaxRuleType[];
    taxRuleListAll: null | TaxRuleType[];
    createContainerError: null | string;
    containersListForDownload: null | ContainerType[];
    airShippingList: null | DetailListStateType;
    isLoadingAir: boolean;
    containerNumbers: ContainerNumberType[] | null;
    filterOrdersData: OrderFilterDataType[] | null;
    containerDetails: ContainerDetailsType | null;
    invoiceDetails: InvoiceDetailType | null;
    invoiceFiltersData: InvoiceFiltersData[] | null;
    shipmentsFiltersData: ShipmentsFilterDataType[] | null;
    airShipmentsFiltersData: ShipmentsFilterDataType[] | null;
    brokerApproveList: BrokerApproveItem[] | null;
    brokerApproveListAll: BrokerApproveItem[] | null;
    isLoadingBrokerApprove: boolean;
    isLoadingTracking: boolean;
    isLoadingContainer: boolean;
    loadingStates: TPortalLoadingStates;
};

const initialState: InitialStateType = {
    isLoading: false,
    isLoadingProduct: false,
    isLoadingInvoice: false,
    isLoadingDetail: false,
    isLoadingTaxRule: false,
    isLoadingContainerFile: false,
    isDownloading: false,
    isDownloadingInvoice: false,
    isDownloadingDetail: false,
    ordersList: null,
    ordersListAll: null,
    containersList: null,
    containersListAll: null,
    invoiceList: null,
    detailList: null,
    taxRuleList: null,
    taxRuleListAll: null,
    createContainerError: null,
    containersListForDownload: null,
    airShippingList: null,
    isLoadingAir: false,
    containerNumbers: null,
    filterOrdersData: null,
    containerDetails: null,
    invoiceDetails: null,
    invoiceFiltersData: null,
    shipmentsFiltersData: null,
    airShipmentsFiltersData: null,
    brokerApproveList: null,
    brokerApproveListAll: null,
    isLoadingBrokerApprove: false,
    isLoadingTracking: false,
    isLoadingContainer: false,
    loadingStates: {
        isLoadingAutoBillingReport: false,
        isDownloadingTemplateFile: false,
    },
};

const portalReducer = (state = initialState, action: PortalActionTypes): InitialStateType => {
    switch (action.type) {
        case SET_BROKER_APPROVE_LIST_ALL: {
            return {
                ...state,
                brokerApproveListAll: action.data,
            };
        }
        case SET_LOADING_BROKER_APPROVE: {
            return {
                ...state,
                isLoadingBrokerApprove: action.data,
            };
        }
        case SET_BROKER_APPROVE_LIST: {
            return {
                ...state,
                brokerApproveList: action.data,
            };
        }
        case SET_IS_LOADING_PRODUCT: {
            return {
                ...state,
                isLoadingProduct: action.data,
            };
        }
        case SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.data,
            };
        }
        case SET_INVOICE_LOADING: {
            return {
                ...state,
                isLoadingInvoice: action.data,
            };
        }
        case SET_DETAIL_LOADING: {
            return {
                ...state,
                isLoadingDetail: action.data,
            };
        }
        case SET_AIR_LOADING: {
            return {
                ...state,
                isLoadingAir: action.data,
            };
        }
        case SET_TAX_RULE_LOADING: {
            return {
                ...state,
                isLoadingTaxRule: action.data,
            };
        }
        case SET_ORDERS_LIST: {
            return {
                ...state,
                ordersList: action.data,
            };
        }
        case SET_ORDERS_LIST_ALL: {
            return {
                ...state,
                ordersListAll: action.data,
            };
        }
        case SET_CONTAINERS_LIST: {
            return {
                ...state,
                containersList: action.data,
            };
        }
        case SET_CONTAINERS_LIST_ALL: {
            return {
                ...state,
                containersListAll: action.data,
            };
        }
        case SET_INVOICE_LIST: {
            return {
                ...state,
                invoiceList: action.data,
            };
        }
        case SET_DETAIL_LIST: {
            return {
                ...state,
                detailList: action.data,
            };
        }
        case SET_AIR_SHIPPING_LIST: {
            return {
                ...state,
                airShippingList: action.data,
            };
        }
        case SET_TAX_RULE_LIST: {
            return {
                ...state,
                taxRuleList: action.data,
            };
        }
        case SET_TAX_RULE_LIST_ALL: {
            return {
                ...state,
                taxRuleListAll: action.data,
            };
        }
        case SET_CREATE_CONTAINER_ERROR: {
            return {
                ...state,
                createContainerError: action.data,
            };
        }
        case SET_LIST_CONTAINERS_FOR_DOWNLOAD: {
            return {
                ...state,
                containersListForDownload: action.data,
            };
        }
        case SET_IS_DOWNLOADING: {
            return {
                ...state,
                isDownloading: action.data,
            };
        }
        case SET_IS_DOWNLOADING_INVOICE: {
            return {
                ...state,
                isDownloadingInvoice: action.data,
            };
        }
        case SET_IS_DOWNLOADING_DETAIL: {
            return {
                ...state,
                isDownloadingDetail: action.data,
            };
        }
        case SET_CONTAINER_NUMBERS: {
            return {
                ...state,
                containerNumbers: action.data,
            };
        }
        case SET_FILTER_ORDERS_DATA: {
            return {
                ...state,
                filterOrdersData: action.data,
            };
        }
        case SET_CONTAINER_DETAILS: {
            return {
                ...state,
                containerDetails: action.data,
            };
        }
        case SET_INVOICE_DETAILS: {
            return {
                ...state,
                invoiceDetails: action.data,
            };
        }
        case SET_INVOICE_FILTERS_DATA: {
            return {
                ...state,
                invoiceFiltersData: action.data,
            };
        }
        case SET_SHIPMENTS_FILTERS_DATA: {
            return {
                ...state,
                shipmentsFiltersData: action.data,
            };
        }
        case SET_AIR_SHIPMENTS_FILTERS_DATA: {
            return {
                ...state,
                airShipmentsFiltersData: action.data,
            };
        }
        case SET_LOADING_TRACKING: {
            return {
                ...state,
                isLoadingTracking: action.data,
            };
        }
        case SET_LOADING_CONTAINER_FILE: {
            return {
                ...state,
                isLoadingContainerFile: action.data,
            };
        }
        case SET_LOADING_CONTAINER: {
            return {
                ...state,
                isLoadingContainer: action.data,
            };
        }
        case SET_LOADING_STATES: {
            return {
                ...state,
                loadingStates: { ...state.loadingStates, ...action.data },
            };
        }
        default:
            return state;
    }
};

export const SetLoadingProduct = (loading: boolean): SetIsLoadingProductType => ({
    type: SET_IS_LOADING_PRODUCT,
    data: loading,
});
export const SetLoading = (loading: boolean): SetIsLoadingType => ({
    type: SET_IS_LOADING,
    data: loading,
});
export const SetInvoiceLoading = (loading: boolean): SetInvoiceLoadingType => ({
    type: SET_INVOICE_LOADING,
    data: loading,
});
export const SetOrdersList = (orders: OrdersListStateType): SetOrdersListType => ({
    type: SET_ORDERS_LIST,
    data: orders,
});
export const SetOrdersListAll = (orders: OrdersListStateType): SetOrdersListAllType => ({
    type: SET_ORDERS_LIST_ALL,
    data: orders,
});
export const SetContainersList = (containers: ContainerType[]): SetContainersListType => ({
    type: SET_CONTAINERS_LIST,
    data: containers,
});
export const SetContainersListAll = (containers: ContainerType[]): SetContainersListAllType => ({
    type: SET_CONTAINERS_LIST_ALL,
    data: containers,
});
export const SetInvoiceList = (invoices: InvoiceType[]): SetInvoiceListType => ({
    type: SET_INVOICE_LIST,
    data: invoices,
});
export const SetDetailList = (details: DetailListStateType): SetDetailListType => ({
    type: SET_DETAIL_LIST,
    data: details,
});
export const SetAirShippingList = (details: DetailListStateType): SetAirShippingListType => ({
    type: SET_AIR_SHIPPING_LIST,
    data: details,
});
export const SetLoadingAirShipping = (loading: boolean): SetAirShippingLoadingType => ({
    type: SET_AIR_LOADING,
    data: loading,
});
export const SetLoadingDetail = (loading: boolean): SetDetailLoadingType => ({
    type: SET_DETAIL_LOADING,
    data: loading,
});

export const SetLoadingTaxRule = (loading: boolean): SetTaxRuleLoadingType => ({
    type: SET_TAX_RULE_LOADING,
    data: loading,
});
export const SetTaxRuleList = (taxRules: TaxRuleType[]): SetTaxRuleListType => ({
    type: SET_TAX_RULE_LIST,
    data: taxRules,
});
export const SetTaxRuleListAll = (taxRules: TaxRuleType[]): SetTaxRuleListAllType => ({
    type: SET_TAX_RULE_LIST_ALL,
    data: taxRules,
});
export const SetCreateContainerError = (error: string | null): SetCreateContainerErrorType => ({
    type: SET_CREATE_CONTAINER_ERROR,
    data: error,
});
export const SetContainersListForDownload = (list: ContainerType[]): SetListContainersForDownloadType => ({
    type: SET_LIST_CONTAINERS_FOR_DOWNLOAD,
    data: list,
});
export const SetIsDownloading = (downloading: boolean): SetIsDownloadingType => ({
    type: SET_IS_DOWNLOADING,
    data: downloading,
});
export const SetIsDownloadingInvoice = (downloading: boolean): SetIsDownloadingInvoiceType => ({
    type: SET_IS_DOWNLOADING_INVOICE,
    data: downloading,
});
export const SetIsDownloadingDetail = (downloading: boolean): SetIsDownloadingDetailsType => ({
    type: SET_IS_DOWNLOADING_DETAIL,
    data: downloading,
});
export const SetContainerNumbers = (data: ContainerNumberType[]): SetContainerNumbersType => ({
    type: SET_CONTAINER_NUMBERS,
    data: data,
});
export const SetFilterOrdersData = (data: OrderFilterDataType[]): SetFilterOrderDataType => ({
    type: SET_FILTER_ORDERS_DATA,
    data: data,
});
export const SetContainerDetails = (data: ContainerDetailsType | null): SetContainerDetailsType => ({
    type: SET_CONTAINER_DETAILS,
    data: data,
});
export const SetInvoiceDetails = (data: InvoiceDetailType | null): SetInvoiceDetailsType => ({
    type: SET_INVOICE_DETAILS,
    data: data,
});
export const SetInvoiceFiltersData = (data: InvoiceFiltersData[]): SetInvoiceFiltersDataType => ({
    type: SET_INVOICE_FILTERS_DATA,
    data: data,
});
export const SetShipmentsFiltersData = (data: ShipmentsFilterDataType[]): SetShipmentsFiltersDataType => ({
    type: SET_SHIPMENTS_FILTERS_DATA,
    data: data,
});
export const SetAirShipmentsFiltersData = (data: ShipmentsFilterDataType[]): SetAirShipmentsFiltersDataType => ({
    type: SET_AIR_SHIPMENTS_FILTERS_DATA,
    data: data,
});
export const SetBrokerApproveList = (data: BrokerApproveItem[]): SetBrokerApproveListType => ({
    type: SET_BROKER_APPROVE_LIST,
    data: data,
});
export const SetBrokerApproveListAll = (data: BrokerApproveItem[]): SetBrokerApproveListAllType => ({
    type: SET_BROKER_APPROVE_LIST_ALL,
    data: data,
});
export const SetLoadingBrokerApprove = (data: boolean): SetLoadingBrokerApproveType => ({
    type: SET_LOADING_BROKER_APPROVE,
    data: data,
});
export const SetLoadingTracking = (loading: boolean): SetLoadingTrackingType => ({
    type: SET_LOADING_TRACKING,
    data: loading,
});
export const SetLoadingContainerFile = (loading: boolean): SetLoadingContainerFileType => ({
    type: SET_LOADING_CONTAINER_FILE,
    data: loading,
});
export const SetLoadingContainer = (loading: boolean): TSetLoadingContainer => ({
    type: SET_LOADING_CONTAINER,
    data: loading,
});

export const SetLoadingStates = (data: { [key in keyof TPortalLoadingStates]?: boolean }): TSetLoadingStates => ({
    type: SET_LOADING_STATES,
    data,
});

export const documentBrokerApprove = (cookies: Cookies, id: string, object: { approved_status: string }, search: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingBrokerApprove(true));
            const response = await brokerApproveAPI.brokerDocumentApprove(cookies, id, object);
            if (response) {
                dispatch(getBrokerApproveList(cookies, search));
            }
            dispatch(SetLoadingBrokerApprove(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingBrokerApprove(false));
                displayError(e.response.data, "Something went wrong during document approval");
            }
        }
    };
};

export const getBrokerApproveListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await brokerApproveAPI.getBrokerApproveListAll(cookies, items, offset, search);
            setProductAllNext(response.data.next !== null ? true : false);
            dispatch(SetBrokerApproveListAll(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getBrokerApproveList = (cookies: Cookies, search: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingBrokerApprove(true));
            const response = await brokerApproveAPI.getBrokerApproveList(cookies, search);
            dispatch(SetBrokerApproveList(response.data.results));
            dispatch(SetLoadingBrokerApprove(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersList = (type: string, cookies: Cookies, productFcFilter: string, productSortFilter: string, tabState: string): ThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingProduct(true));
            const response = await packingPortalAPI.getOrdersList(type, cookies, productFcFilter, productSortFilter, tabState);
            const { ordersList } = getState().portal;
            dispatch(SetOrdersList(ordersList ? { ...ordersList, [tabState]: response.data.results } : { [tabState]: response.data.results }));
            dispatch(SetLoadingProduct(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrdersListAll = (
    type: string,
    cookies: Cookies,
    productFcFilter: string,
    productSortFilter: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    tabState: string
): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const response = await packingPortalAPI.getOrdersListAll(type, cookies, productFcFilter, productSortFilter, items, offset, tabState);
            setProductAllNext(response.data.next !== null ? true : false);
            const { ordersListAll } = getState().portal;
            dispatch(SetOrdersListAll(ordersListAll ? { ...ordersListAll, [tabState]: response.data.results } : { [tabState]: response.data.results }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getContainersList = (id: string, type: string, cookies: Cookies, searchFilter: string, statusFilter: string, sortFilter: string, tabState: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainer(true));
            const response = await packingPortalAPI.getContainersList(id, type, cookies, searchFilter, statusFilter, sortFilter, tabState);
            dispatch(SetContainersList(response.data.results));
            dispatch(SetLoadingContainer(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getContainersListAll = (
    id: string,
    type: string,
    cookies: Cookies,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string,
    items: number,
    offset: number,
    setStoredProductAllNext: (value: boolean) => void,
    tabState: string
): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await packingPortalAPI.getContainersListAll(id, type, cookies, searchFilter, statusFilter, sortFilter, items, offset, tabState);
            setStoredProductAllNext(response.data.next !== null ? true : false);
            dispatch(SetContainersListAll(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const createContainer = (
    data: CreatePortalContainerType,
    id: string,
    type: string,
    cookies: Cookies,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainer(true));
            const response = await packingPortalAPI.createContainer(data, cookies);
            if (response) {
                dispatch(getContainersList(id, type, cookies, searchFilter, statusFilter, sortFilter, "US"));
            }
            dispatch(getContainersNumbers(cookies, type, "US"));
            dispatch(SetLoadingContainer(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingContainer(false));
                displayError(e.response.data, "Something went wrong while creating the container");
            }
        }
    };
};

export const addOrderToContainer = (
    ordersList: string[],
    containerId: string,
    userId: string,
    type: string,
    invoiceTypeTab: string,
    productType: string,
    cookies: Cookies,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string,
    productFcFilter: string,
    productSortFilter: string,
    setInvoiceNext: (value: boolean) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainer(true));
            await packingPortalAPI.addOrderToContainer(ordersList, containerId, cookies);
            dispatch(getContainersList(userId, type, cookies, searchFilter, statusFilter, sortFilter, "US"));
            dispatch(getOrdersList(productType, cookies, productFcFilter, productSortFilter, "US"));
            dispatch(getInvoiceList(userId, invoiceTypeTab, cookies, LAZY_COUNT, 0, setInvoiceNext, "US"));
            dispatch(getOrderFilterData(cookies, productType, "US"));
            dispatch(SetLoadingContainer(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    dispatch(SetCreateContainerError("Space limit"));
                    dispatch(SetLoadingContainer(false));
                } else {
                    console.log(e);
                }
            }
        }
    };
};

export const updateContainer = (
    userId: string,
    type: string,
    invoiceTypeTab: string,
    containerId: string,
    data: EditContainerSubmitData,
    cookies: Cookies,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string,
    setInvoiceNext: (value: boolean) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainer(true));
            await packingPortalAPI.updateContainer(containerId, data, cookies);
            dispatch(getContainersList(userId, type, cookies, searchFilter, statusFilter, sortFilter, "US"));
            dispatch(getContainersNumbers(cookies, type, "US"));
            dispatch(getInvoiceList(userId, invoiceTypeTab, cookies, LAZY_COUNT, 0, setInvoiceNext, "US"));
            dispatch(SetLoadingContainer(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingContainer(false));
                displayError(e.response.data, "Something went wrong while updating the container");
            }
        }
    };
};

export const deleteContainer = (
    containerId: string,
    userId: string,
    type: string,
    invoiceTypeTab: string,
    cookies: Cookies,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string,
    detailTypeTab: string,
    productType: string,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    containerFilter: string,
    productFcFilter: string,
    productSortFilter: string,
    setInvoiceNext: (value: boolean) => void,
    setProductAllNext: (value: boolean) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainer(true));
            await packingPortalAPI.deleteContainer(containerId, cookies);
            dispatch(getContainersList(userId, type, cookies, searchFilter, statusFilter, sortFilter, "US"));
            dispatch(getOrdersList(productType, cookies, productFcFilter, productSortFilter, "US"));
            dispatch(getInvoiceList(userId, invoiceTypeTab, cookies, LAZY_COUNT, 0, setInvoiceNext, "US"));
            dispatch(getContainersNumbers(cookies, type, "US"));
            dispatch(getOrderFilterData(cookies, productType, "US"));
            dispatch(getDetailList(detailTypeTab, cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, containerFilter, LAZY_COUNT, 0, setProductAllNext, "US"));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingContainer(false));
                displayError(e.response.data, "Something went wrong while deleting the container");
            }
        }
    };
};

export const deleteOrderFromContainer = (
    orderId: string,
    containerId: string,
    userId: string,
    type: string,
    productType: string,
    cookies: Cookies,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string,
    detailTypeTab: string,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    containerFilter: string,
    productFcFilter: string,
    productSortFilter: string,
    setInvoiceNext: (value: boolean) => void,
    setProductAllNext: (value: boolean) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainer(true));
            await packingPortalAPI.deleteOrderFromContainer(orderId, containerId, cookies);
            dispatch(getContainersList(userId, type, cookies, searchFilter, statusFilter, sortFilter, "US"));
            dispatch(getOrdersList(productType, cookies, productFcFilter, productSortFilter, "US"));
            dispatch(getInvoiceList(userId, type, cookies, LAZY_COUNT, 0, setInvoiceNext, "US"));
            dispatch(getDetailList(detailTypeTab, cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, containerFilter, LAZY_COUNT, 0, setProductAllNext, "US"));
            dispatch(getOrderFilterData(cookies, productType, "US"));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingContainer(false));
                displayError(e.response.data, "Something went wrong while removing an order from the container");
            }
        }
    };
};

export const getInvoiceList = (
    tenantId: string,
    containerType: string,
    cookies: Cookies,
    items: number,
    offset: number,
    setInvoiceNext: (value: boolean) => void,
    tabState: string
): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await invoicePortalAPI.getInvoiceList(tenantId, containerType, cookies, items, offset, tabState);
            setInvoiceNext(response.data.next !== null ? true : false);
            dispatch(SetInvoiceList(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const closeContainer = (
    containerId: string,
    status: boolean,
    tenantId: string,
    containerType: string,
    invoiceTypeTab: string,
    cookies: Cookies,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string,
    detailTypeTab: string,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    containerFilter: string,
    setInvoiceNext: (value: boolean) => void,
    setProductAllNext: (value: boolean) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainer(true));
            await packingPortalAPI.closeContainer(containerId, status, cookies);
            dispatch(getContainersList(tenantId, containerType, cookies, searchFilter, statusFilter, sortFilter, "US"));
            dispatch(getInvoiceList(tenantId, invoiceTypeTab, cookies, LAZY_COUNT, 0, setInvoiceNext, "US"));
            dispatch(getInvoiceFiltersData(cookies, invoiceTypeTab, "US"));
            dispatch(getContainersNumbers(cookies, containerType, "US"));
            dispatch(getDetailList(detailTypeTab, cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, containerFilter, LAZY_COUNT, 0, setProductAllNext, "US"));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingContainer(false));
                displayError(e.response.data, "Something went wrong while updating the container status");
            }
        }
    };
};

export const getDetailList = (
    type: string,
    cookies: Cookies,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    containerFilter: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    tabState: string
): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const response = await detailPortalAPI.getDetailList(type, cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, containerFilter, items, offset, tabState);
            const { detailList } = getState().portal;
            dispatch(SetDetailList(detailList ? { ...detailList, [tabState]: response.data.results } : { [tabState]: response.data.results }));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getAirShippingList = (
    cookies: Cookies,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    tabState: string
): ThunkType => {
    return async (dispatch, getState) => {
        try {
            const response = await airShippingPortalAPI.getAirShippingList(cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, items, offset, tabState);
            const { airShippingList } = getState().portal;
            dispatch(SetAirShippingList(airShippingList ? { ...airShippingList, [tabState]: response.data.results } : { [tabState]: response.data.results }));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const getTaxRuleList = (cookies: Cookies, search: string, tabState: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingTaxRule(true));
            const response = await taxRulePortalAPI.getTaxRuleList(cookies, search, tabState);
            dispatch(SetTaxRuleList(response.data.results));
            dispatch(SetLoadingTaxRule(false));
        } catch (e) {
            console.log(e);
        }
    };
};

export const updateTaxRuleItem = (
    id: string,
    data: TaxRuleUpdateType,
    cookies: Cookies,
    detailTypeTab: string,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    containerFilter: string,
    search: string,
    setProductAllNext: (value: boolean) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingTaxRule(true));
            await taxRulePortalAPI.updateTaxRule(id, data, cookies);
            dispatch(getTaxRuleList(cookies, search, "US"));
            dispatch(getDetailList(detailTypeTab, cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, containerFilter, LAZY_COUNT, 0, setProductAllNext, "US"));
            displaySuccess("Tax rule updated successfully");
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingTaxRule(false));
                displayError(e.response.data, "Something went wrong during updating tax rule");
            }
        }
    };
};

export const getContainersListForDownload = (id: string, type: string, cookies: Cookies, searchFilter: string, statusFilter: string, sortFilter: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await packingPortalAPI.getContainersList(id, type, cookies, searchFilter, statusFilter, sortFilter, "US");
            dispatch(SetContainersListForDownload(response.data.results));
        } catch (e) {
            console.log(e);
        }
    };
};

export const createContainerFile = (containerId: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetIsDownloading(true));
            const response = await packingPortalAPI.createPackingListFile(containerId, cookies);
            if (response) {
                window.open(response.data.url);
            }
            dispatch(SetIsDownloading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsDownloading(false));
                displayError(e.response.data, "Something went wrong while downloading container file");
            }
        }
    };
};

export const createInvoiceFile = (invoiceId: string, cookies: Cookies): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetIsDownloadingInvoice(true));
            const response = await invoicePortalAPI.createInvoiceFile(invoiceId, cookies);
            if (response) {
                window.open(response.data.url);
            }
            dispatch(SetIsDownloadingInvoice(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsDownloadingInvoice(false));
                displayError(e.response.data, "Something went wrong while downloading invoice file");
            }
        }
    };
};

export const createDetailFile = (orders: string[], containerNumber: string, cookies: Cookies, tabState: string): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetIsDownloadingDetail(true));
            const response = await detailPortalAPI.createDetailFile(orders, containerNumber, cookies, tabState);
            if (response) {
                window.open(response.data.url);
            }
            dispatch(SetIsDownloadingDetail(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
                dispatch(SetIsDownloadingDetail(false));
            }
        }
    };
};

export const getTaxRuleListAll = (cookies: Cookies, items: number, offset: number, setProductAllNext: (value: boolean) => void, search: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await taxRulePortalAPI.getTaxRuleListAll(cookies, items, offset, search);
            dispatch(SetTaxRuleListAll(response.data.results));
            setProductAllNext(response.data.next !== null ? true : false);
        } catch (e) {
            console.log(e);
        }
    };
};

export const downloadProductList = (
    cookies: Cookies,
    productsList: DetailFileObjectType[],
    type: string,
    productFcFilter: string,
    productSortFilter: string,
    tabState: string
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetIsDownloading(true));
            const response = await packingPortalAPI.downloadProductsList(cookies, productsList, tabState);
            window.open(response.data.url);
            dispatch(getOrdersList(type, cookies, productFcFilter, productSortFilter, tabState));
            dispatch(getOrderFilterData(cookies, type, tabState));
            dispatch(SetIsDownloading(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetIsDownloading(false));
                displayError(e.response.data, "Something went wrong while downloading product list file");
            }
        }
    };
};

export const getContainersNumbers = (cookies: Cookies, type: string, tabState: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await packingPortalAPI.getContainersForDropdown(cookies, type, tabState);
            dispatch(SetContainerNumbers(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getOrderFilterData = (cookies: Cookies, type: string, tabState: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await packingPortalAPI.getOrdersFiltersData(cookies, type, tabState);
            dispatch(SetFilterOrdersData(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getContainerDetails = (cookies: Cookies, id: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await packingPortalAPI.getContainerDetails(cookies, id);
            dispatch(SetContainerDetails(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getInvoiceDetails = (cookies: Cookies, id: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await invoicePortalAPI.getInvoiceDetails(cookies, id);
            dispatch(SetInvoiceDetails(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getInvoiceFiltersData = (cookies: Cookies, type: string, tabState: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await invoicePortalAPI.getInvoiceFiltersData(cookies, type, tabState);
            dispatch(SetInvoiceFiltersData(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getShipmentsFiltersData = (
    cookies: Cookies,
    type: string,
    searchDetailFilter: string,
    generatedStartDate: string,
    generatedEndDate: string,
    tabState: string
): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await detailPortalAPI.getShipmentsFiltersData(cookies, type, searchDetailFilter, generatedStartDate, generatedEndDate, tabState);
            dispatch(SetShipmentsFiltersData(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const getAirShipmentsFiltersData = (cookies: Cookies, type: string, tabState: string): ThunkType => {
    return async (dispatch) => {
        try {
            const response = await detailPortalAPI.getShipmentsFiltersData(cookies, type, "", "", "", tabState);
            dispatch(SetAirShipmentsFiltersData(response.data));
        } catch (e) {
            console.log(e);
        }
    };
};

export const setSeaShipmentsTrackingId = (
    cookies: Cookies,
    data: AddShipmentTrackingDataType,
    successMessage: string,
    type: string,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    containerFilter: string,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    tabState: string
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingTracking(true));
            const responce = await packingPortalAPI.setShipmentsTrackingId(cookies, data);
            if (responce.status === 200) {
                displaySuccess(successMessage);
                dispatch(getDetailList(type, cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, containerFilter, items, offset, setProductAllNext, tabState));
            }
            dispatch(SetLoadingTracking(false));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingTracking(false));
                displayError(e.response.data, "Something went wrong while setting a Tracking ID");
            }
        }
    };
};

export const setAirShipmentsTrackingId = (
    cookies: Cookies,
    data: AddShipmentTrackingDataType,
    successMessage: string,
    searchDetailFilter: string,
    sortDetailFilter: string,
    periodDetailFilter: PeriodDetailFilterType,
    items: number,
    offset: number,
    setProductAllNext: (value: boolean) => void,
    tabState: string
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingTracking(true));
            const responce = await packingPortalAPI.setShipmentsTrackingId(cookies, data);
            if (responce.status === 200) {
                displaySuccess(successMessage);
                dispatch(getAirShippingList(cookies, searchDetailFilter, sortDetailFilter, periodDetailFilter, items, offset, setProductAllNext, tabState));
            }
            dispatch(SetLoadingTracking(false));
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response && e.response.status === 400) {
                    displayError(e.response.data);
                    dispatch(SetLoadingTracking(false));
                }
            }
        }
    };
};

export const uploadContainerFile = (
    cookies: Cookies,
    file: File,
    updateExisting: boolean,
    closeModal: () => void,
    userId: string,
    type: string,
    productType: string,
    searchFilter: string,
    statusFilter: string,
    sortFilter: string,
    productFcFilter: string,
    productSortFilter: string,
    setInvoiceNext: (value: boolean) => void
): ThunkType => {
    return async (dispatch) => {
        try {
            dispatch(SetLoadingContainerFile(true));

            const response = await packingPortalAPI.uploadContainerFile(cookies, file, updateExisting);

            dispatch(getContainersList(userId, type, cookies, searchFilter, statusFilter, sortFilter, "US"));
            dispatch(getOrdersList(productType, cookies, productFcFilter, productSortFilter, "US"));
            dispatch(getInvoiceList(userId, type, cookies, LAZY_COUNT, 0, setInvoiceNext, "US"));
            dispatch(getOrderFilterData(cookies, productType, "US"));

            dispatch(SetLoadingContainerFile(false));

            closeModal();
            displaySuccess(response.data);
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (e.response.status === 400) {
                    await dispatch(getOrdersList(productType, cookies, productFcFilter, productSortFilter, "US"));
                }
                dispatch(SetLoadingContainerFile(false));
                closeModal();
                displayError(e.response.data);
            }
        }
    };
};

export const createContainerTemplateFile = (containerId: string): ThunkType => {
    return async (dispatch, gatState) => {
        try {
            const { cookies } = gatState().auth;
            dispatch(SetLoadingStates({ isDownloadingTemplateFile: true }));

            const response = await packingPortalAPI.createContainerTemplateFile(containerId, cookies);
            if (response) {
                window.open(response.data.url);
            }

            dispatch(SetLoadingStates({ isDownloadingTemplateFile: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                dispatch(SetLoadingStates({ isDownloadingTemplateFile: false }));
                displayError(e.response.data, "Something went wrong while downloading container template file");
            }
        }
    };
};

export const postDownloadPortalBillingFile = (type: "freight" | "tariff", data: { start_date: string | Date; end_date: string | Date }): ThunkType => {
    return async (dispatch, getState) => {
        try {
            dispatch(SetLoadingStates({ isLoadingAutoBillingReport: true }));

            const cookies = getState().auth.cookies;

            const response = await invoicePortalAPI.postDownloadPortalBillingFile(cookies, type, data);

            window.open(response.data.url, "_blank");

            dispatch(SetLoadingStates({ isLoadingAutoBillingReport: false }));
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                displayError(e.response.data);
                dispatch(SetLoadingStates({ isLoadingAutoBillingReport: false }));
            }
        }
    };
};

export default portalReducer;
